import React from "react"
import Layout from "../../components/layout"
import ConfirmSignup from "../../components/security/confirmSignup"

const ConfirmSignupPage = () => {
  return (
    <Layout>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="data-loading">
                <i class="fal fa-spinner-third"></i>
                <div>جاري التحميل...</div>
              </div>
              <ConfirmSignup></ConfirmSignup>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ConfirmSignupPage
