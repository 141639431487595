import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import { Auth } from "aws-amplify"
import queryString from "query-string"
import { Location } from "@reach/router"
import Notifications, { notify } from "react-notify-toast"
import { showHideLogin } from "../user-menu"

const ConfirmSignup = path => {
  useEffect(() => {
    async function confirmUser(username, authCode) {
      try {
        await Auth.confirmSignUp(username, authCode)
        console.log("Successfully signed up!")
        notify.show(
          "تم تأكيد حسابك بنجاح، الرجاء تسيجل الدخول",
          "success",
          3000
        )
        showHideLogin()
      } catch (err) {
        console.log("error confirming signing up...", err)
        notify.show("حدث خطأ", "success", 3000)
      }
    }
    var q = queryString.parse(location.search)
    confirmUser(q.u, q.v)
  })

  return <></>
}

export default ConfirmSignup
